import styled from 'styled-components';
import {
  ADDTOCART_BUTTON_LABEL,
  CATALOGWECOM_APPNAME,
  FINISH_BUTTON_LABEL,
  INSTOCK_BUTTON_LABEL,
  LEADTIME_BUTTON_LABEL,
  OOB_APPNAME,
  RESET_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
} from '../../../utils/constants';

export const LabelBold = styled.span(({ summaryPage = false }) => {
  return `
  font-weight: 600;
`;
});

export const ButtonContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['disabled'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    disabled = false,
  }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    background-color: #FFFFFF;
    padding: 10px 13px;
    font-family: LouisVuitton_Regular;

    ${disabled
      ? `
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      `
      : ''
    }

    ${isIpad
      ? `
        font-size: 14px;
        gap: 9px;
        padding: 10px 20px;
      `
      : isDesktop
        ? `
          font-size: 14px;
          gap: 9px;
          padding: 10px 20px;
        `
        : ''
    }

    border: none;
    padding: 0;

    > img {
      width: 25px;
    }

    ${isIpad
      ? `
        border: 1px solid #E6E6E6;
        border-radius: 100px;
        padding: 10px 20px;

        > img {
          width: 20px;
        }
        `
      : isDesktop
        ? `
          border: 1px solid #E6E6E6;
          border-radius: 100px;
          padding: 10px 20px;

          > img {
            width: 20px;
          }
        `
        : ''
    }

    ${appName === OOB_APPNAME && isDesktop
      ? `
          border: none;
          padding: 0;
        `
      : ''
    }
  `
);


export const ButtonContainerShimmer = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => {
    return `
  /* NOTE: Goal is to have the shimmer same size as the Reset button
     for better visuals. */
  ${isMobile
        ? `
  border-radius: 30px;
  padding: 18px 35px;
`
        : `
  border-radius: 100px;
  padding: 17px 45px;
`
      }

    background: linear - gradient(to right, #eff1f3 4 %, #e2e2e2 25 %, #eff1f3 36 %);
    animation: shimmer 20s infinite linear;

    @keyframes shimmer {
      0 % {
        background- position: -80vw 0;
    }
    100 % {
      background- position: 80vw 0;
  }
  }

`;
  }
);
