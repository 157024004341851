import connection from '../services/connection';
import threekitAPI from '../services';
import { initializeI18n } from '../i18n/i18n';
import {
  shallowCompare,
  deepCompare,
  getCameraPosition,
  setCameraPosition,
  prepAttributeForComponent,
  dataURItoFile,
  getParams,
  objectToQueryStr,
  assetsRules,
  waitForDataDrivenConfigurator,
  waitForDataDrivenExtensionConfigurator,
} from '../utils/functions.js';
import {
  SNAPSHOT_FORMATS,
  SKU_ATTRIBUTE_NAME,
  DEFAULT_PLAYER_CONFIG,
  TK_SAVED_CONFIG_PARAM_KEY,
  METADATA_RESERVED,
  ATTRIBUTE_TYPES,
  DEFAULT_CAMERA_CONFIG,
} from '../utils/constants';

class Controller {
  constructor({
    product,
    //player,
    configurator,
    //translations,
    language,
    //toolsList,
    attributeGrouping,
    config,
    settings,
  }) {
    this._product = product;
    this._api = threekitAPI;
    //this._player = player.enableApi('player');
    //this._translations = translations;
    this._currentLanguage = language;
    this._history = [[{}, configurator.getConfiguration()]];
    this._historyPosition = 0;
    //this._toolsList = toolsList || new Set([]);
    this._savedConfiguration = '';
    this._config = config;
    this._settings = settings;
    this._codeData = Object.entries(configurator.getMetadata()).reduce(
      (output, [key, value]) => {
        if (
          !key.includes('.code') &&
          !key.includes('.type') &&
          !key.includes(`.${METADATA_RESERVED.valueSku}`) &&
          !key.includes(`.${METADATA_RESERVED.valueCode}`)
        )
          return output;

        const [attrName, dataKey, stringKey] = key.split('.');

        if (dataKey === METADATA_RESERVED.valueCode) {
          const val = { [stringKey]: value };
          if (output[attrName]) {
            output[attrName].codeValues = Object.assign(
              {},
              output[attrName]?.codeValues,
              val
            );
          } else output[attrName] = { codeValues: val };
        } else if (dataKey === METADATA_RESERVED.valueSku) {
          const val = { [stringKey]: value };
          if (output[attrName]) {
            output[attrName].skuValues = Object.assign(
              {},
              output[attrName]?.skuValues,
              val
            );
          } else output[attrName] = { skuValues: val };
        } else {
          if (output[attrName]) output[attrName][dataKey] = value;
          else output[attrName] = { [dataKey]: value };
        }

        return output;
      },
      {}
    );
  }

  takeSnapshots = async (snapshotsConfig) => {
    const size = snapshotsConfig?.size || DEFAULT_CAMERA_CONFIG.size;
    const format = snapshotsConfig?.format || DEFAULT_CAMERA_CONFIG.format;
    const attributeName =
      snapshotsConfig?.attributeName || DEFAULT_CAMERA_CONFIG.attributeName;

    let snapshotsRaw = {};

    const cameras = window.threekit.configurator
      .getDisplayAttributes()
      .find((el) => el.name === attributeName)
      ?.values.filter((el) => el.tags.includes('snapshot'));

    const currentCamera =
      window.threekit.configurator
        .getDisplayAttributes()
        .find((el) => el.name === attributeName)?.value || undefined;
    const cameraPosition = getCameraPosition(window.threekit.player.camera);

    snapshotsRaw = (await getSnapshots(cameras, snapshotsConfig)) || {};

    await window.threekit.configurator.setConfiguration({
      [attributeName]: currentCamera,
    });

    setCameraPosition(window.threekit.player.camera, cameraPosition);

    const files = Object.entries(snapshotsRaw).reduce(
      (output, [key, el], idx) => {
        const file = dataURItoFile(el, `${key}.${format}`);
        return Object.assign(output, { [key]: file });
      },
      {}
    );
    return Promise.resolve(files);

    function getSnapshots(cameras) {
      let snapshots = {};

      return cameras?.reduce((snapshotPromise, camera) => {
        return snapshotPromise.then(
          () =>
            new Promise(async (resolve) => {
              if (camera)
                await window.threekit.configurator.setConfiguration({
                  [attributeName]: { assetId: camera.assetId },
                });
              const snapshotStr = await window.threekit.player.snapshotAsync({
                size,
                mimeType: `image/${SNAPSHOT_FORMATS[format]}`,
              });
              snapshots[camera.name] = snapshotStr;
              resolve(snapshots);
            })
        );
      }, Promise.resolve(snapshots));
    }
  };

  static createPlayerLoaderEl() {
    let playerElement = document.getElementById('player-root');
    if (playerElement) return playerElement;

    playerElement = document.createElement('div');
    playerElement.setAttribute('id', 'player-root');
    playerElement.style.height = '100%';

    const playerLoader = document.createElement('div');
    playerLoader.appendChild(playerElement);
    playerLoader.style.opacity = '0';
    playerLoader.style.position = 'fixed';
    playerLoader.style.top = '-110%';
    playerLoader.style.height = '1px';

    document.body.appendChild(playerLoader);
    return playerElement;
  }

  static createThreekitScriptEl(threekitEnv, scriptPath) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      if (scriptPath) {
        script.src = `${threekitEnv}${scriptPath}`;
      } else {
        script.src = `${threekitEnv}/app/js/threekit-player-bundle.js`;
      }
      script.id = 'threekit-player-bundle';
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  static initThreekit(config) {
    return new Promise(async (resolve) => {
      const updatedConfig = {
        ...config,
      };
      const player = await window.threekitPlayer(updatedConfig);

      console.log('before player 2D');
      window.player = player;
      const configurator = await player.getConfigurator();
      await waitForDataDrivenExtensionConfigurator();
      await window.dataDrivenConfiguratorExtension?.getStatus();
      //configurator.prefetchAttributes(['Rotate Model']);
      //resolve({ player, configurator });
      resolve({ configurator });
    });
  }

  static attachPlayerToComponent(moveToElementId) {
    const addPlayer = (tryCount = 0) => {
      if (tryCount >= 10) return;

      let player = document.getElementById('player-root');
      const playerWrapper = document.getElementById(moveToElementId);

      if (!player || !playerWrapper)
        return setTimeout(() => {
          addPlayer(tryCount + 1);
        }, 0.05 * 1000);

      if (!player) throw new Error('Initial Player element not found');
      if (!playerWrapper) throw new Error('Move To element not found');

      playerWrapper.appendChild(player);
    };

    addPlayer();
  }

  static getConfiguration(configurationId) {
    return new Promise(async (resolve) => {
      if (!configurationId) {
        if (!window.threekit) return resolve();
        resolve(window.threekit.controller._player.getFullConfiguration());
      }
      const config = await threekitAPI.configurations.fetchThreekit(
        configurationId
      );
      if (!config) throw new Error('No config find for this Recipe Id');
      resolve(config);
    });
  }

  static async launch(config) {
    return new Promise(async (resolve) => {
      if (window.threekit) resolve();
      const {
        authToken,
        orgId,
        elementId,
        cache,
        stageId,
        assetId,
        showConfigurator,
        initialConfiguration: initialConfigurationRaw,
        initialConfigurationId,
        showLoadingThumbnail,
        showLoadingProgress,
        onLoadingProgress,
        showAR,
        showShare,
        locale,
        allowMobileVerticalOrbit,
        publishStage,
        threekitEnv: threekitEnvRaw,
        serverUrl,
        useProxy,
        //additionalTools,
        attrGroupingTableId,
        scriptPath,
        translationTableId,
        isChina,
        compression,
      } = Object.assign(DEFAULT_PLAYER_CONFIG, config);

      let el = document.getElementById(elementId);
      if (!el) el = this.createPlayerLoaderEl();

      let connectionConfig = {
        authToken,
        orgId,
        assetId,
        threekitEnv: threekitEnvRaw,
        serverUrl,
        useProxy,
        translationTableId,
      };
      connection.connect(connectionConfig);
      initializeI18n(translationTableId);

      const { threekitEnv } = connection.getConnection();

      let initialConfiguration = { ...initialConfigurationRaw };
      let updatedAssetId = assetId;
      const params = getParams();

      const sku = params.sku;
      let productId;
      if (sku) {
        window.dataDrivenConfiguratorInitialSku = sku;
        productId = await threekitAPI.configurations.getProductIdBySku(sku);
      }

      if (true) {
        const configuration = await this.getConfiguration(
          params[TK_SAVED_CONFIG_PARAM_KEY] ||
          initialConfigurationId ||
          'THVPJLM9'
          //'oT_iO1bHQ' //vFm5qi0Gx
        );
        if (configuration) {
          initialConfiguration = Object.assign(
            {},
            configuration.variant,
            initialConfigurationRaw
          );
          connection.connect({
            ...connectionConfig,
            assetId: configuration.productId,
          });
          updatedAssetId = productId || configuration.productId;
        }
      }

      const product = await threekitAPI.products.getProduct(updatedAssetId);

      console.log('product', product);

      await this.createThreekitScriptEl(threekitEnv, scriptPath);
      const [
        { configurator },
        //{ player, configurator },
        // [translations, translationErrors],
        attributeGrouping,
      ] = await Promise.all([
        this.initThreekit({
          el,
          authToken,
          orgId,
          cache,
          stageId,
          assetId: updatedAssetId,
          threekitEnv,
          showConfigurator,
          initialConfiguration,
          showLoadingThumbnail,
          showLoadingProgress,
          onLoadingProgress,
          showAR,
          showShare,
          locale,
          allowMobileVerticalOrbit,
          publishStage,
          isChina,
          compression,
        }),
        // threekitAPI.products.fetchTranslations(),
      ]);
      // let toolsList = new Set([]);
      // if (additionalTools?.length) {
      //   additionalTools.flat().forEach((toolFunc) => {
      //     const tool = toolFunc(player);
      //     if (toolsList.has(tool.key)) return;
      //     toolsList.add(tool.key);
      //     player.tools.addTool(tool);
      //   });
      // }


      window.threekit = {
        //player,
        configurator,
        controller: new Controller({
          product,
          //player,
          configurator,
          //translations: translations,
          language: locale,
          //toolsList,
          attributeGrouping,
          config: {
            initialConfiguration,
          },
          settings: config,
        }),
      };
      resolve();
    });
  }

  _prepThumbnails(attr) {
    if (attr.type.toLowerCase() !== 'asset') return attr;
    const { useProxy, threekitEnv } = connection.getConnection();
    const attribute = { ...attr };
    attribute.values = attribute.values.map((el) => {
      const prepped = { ...el };
      if (prepped.metadata._thumbnail) {
        prepped.metadata._thumbnail = `${threekitEnv}/api/images/webp/200x0/${useProxy
          ? `${prepped.metadata._thumbnail.replace(
            'https://preview.threekit.com',
            threekitEnv
          )}?cacheScope=123&cacheMaxAge=31536000`
          : prepped.metadata._thumbnail
          }`;
      }
      return prepped;
    });
    return attribute;
  }

  _translateValidAttributes(data) {
    return data.map((attr) => {
      const translatedName =
        this._translations?.[attr.name]?.[this._currentLanguage] || attr.name;

      return {
        ...attr,
        name: translatedName,
      };
    });
  }

  _translateAttribute(attr) {
    return {
      ...attr,
      label:
        this._translations?.[attr.name]?.[this._currentLanguage] || attr.name,
      values: !Array.isArray(attr.values)
        ? attr.values
        : attr.values.map((el) =>
          Object.assign({}, el, {
            label:
              this._translations?.[
              attr.type === ATTRIBUTE_TYPES.string ? el.label : el.name
              ]?.[this._currentLanguage] ||
              (attr.type === ATTRIBUTE_TYPES.string ? el.label : el.name),
            name:
              this._translations?.[
              attr.type === ATTRIBUTE_TYPES.string ? el.label : el.name
              ]?.[this._currentLanguage] ||
              (attr.type === ATTRIBUTE_TYPES.string ? el.label : el.name),
          })
        ),
    };
  }

  _getAttributeValues(config) {
    const attributes =
      window.threekit.configurator.getDisplayAttributes(config);
    return attributes.reduce((output, attr) => {
      const valueData = attr.values?.find((el) => {
        if (attr.type === ATTRIBUTE_TYPES.asset)
          return el.assetId === attr.value.assetId;
        else return el.value === attr.value;
      });
      if (!valueData) return output;

      return Object.assign(output, {
        [attr.name]: Object.assign(
          {},
          attr.type === ATTRIBUTE_TYPES.asset ? attr.value : undefined,
          valueData
        ),
      });
    }, {});
  }

  _compareAttributes(attributes1, attributes2) {
    let updatedAttributes = new Set([]);

    const attributesObj1 = attributes1.reduce(
      (output, el) => Object.assign(output, { [el.name]: el }),
      {}
    );
    const attributesObj2 = attributes2.reduce(
      (output, el) => Object.assign(output, { [el.name]: el }),
      {}
    );
    const attrKeys1 = Object.keys(attributesObj1);
    const attrKeys2 = Object.keys(attributesObj2);

    //  We compare the attributes on in each object
    attrKeys2
      .filter((attribute) => attrKeys1.indexOf(attribute) === -1)
      .forEach((attribute) => updatedAttributes.add(attribute));
    attrKeys1
      .filter((attribute) => attrKeys2.indexOf(attribute) === -1)
      .forEach((attribute) => updatedAttributes.add(attribute));

    for (let key of attrKeys1) {
      const attr1 = attributesObj1[key];
      const attr2 = attributesObj2[key];

      if (!attr1 || !attr2) continue;

      if (!shallowCompare(attr1.value, attr2.value)) {
        updatedAttributes.add(key);
        continue;
      }

      if (!deepCompare(attr1.values, attr2.values)) {
        updatedAttributes.add(key);
        continue;
      }
    }

    return Array.from(updatedAttributes);
  }

  _updateConfiguration(configuration) {
    return new Promise(async (resolve) => {
      const currentState = JSON.parse(
        JSON.stringify(window.threekit.configurator.getDisplayAttributes())
      );
      await window.threekit.configurator.setConfiguration(configuration);
      const updatedState = window.threekit.configurator.getDisplayAttributes();
      const updatedAttrs = this._compareAttributes(currentState, updatedState);
      if (updatedAttrs?.length) this._savedConfiguration = undefined;
      resolve(updatedAttrs);
    });
  }

  getForm(config) {
    const attributeArr =
      window.threekit.configurator.getDisplayAttributes(config);
    const attributes = attributeArr.reduce((output, attr) => {
      const prepped = this._prepThumbnails(attr);
      return Object.assign(output, {
        [attr.name]: prepped,
        //[attr.name]: this._translateAttribute(prepped),
      });
    }, {});
    return attributes;

    const formGroupEntries = new Map(Object.entries(this._attributeGrouping));
    const attributeEntries = Object.entries(attributes).filter(
      (el) => el[0] !== 'CameraAngle'
    );

    const form = new Map();

    attributeEntries.forEach(([attrName, attrData]) => {
      for (let [groupName, groupAttributes] of formGroupEntries) {
        if (
          Array.isArray(groupAttributes) &&
          groupAttributes.includes(attrName)
        ) {
          const preppedAttr = prepAttributeForComponent(attrData, {}, 'form');

          if (form.has(groupName)) {
            form.get(groupName)[attrName] = preppedAttr;
          } else {
            form.set(groupName, { [attrName]: preppedAttr });
          }
          break;
        }
      }
    });

    return Object.fromEntries(form);
  }

  getProduct() {
    if (!window.threekit) return undefined;
    return window.threekit.controller._product;
  }

  getAttributeGrouping() {
    if (!window.threekit) return undefined;
    return window.threekit.controller._attributeGrouping;
  }

  getConfig() {
    if (!window.threekit) return undefined;
    return window.threekit.controller._config;
  }

  // getName() {
  //   if (!window.threekit) return undefined;
  //   return window.threekit.player.scene.get(window.threekit.player.instanceId)
  //     .name;
  // }

  getPrice() {
    if (!this._priceConfig?.id) return null;
    const price = window.threekit.configurator.getPrice(
      this._priceConfig.id,
      this._priceConfig.currency
    );
    return { price, currency: this._priceConfig.currency };
  }

  // addTool(tools) {
  //   if (!tools) return;
  //   const toolsToAdd = Array.isArray(tools) ? tools : [tools];

  //   toolsToAdd.flat().forEach((toolFunc) => {
  //     const tool = toolFunc(window.threekit.player);
  //     if (this._toolsList.has(tool.key))
  //       return console.log(`The tool '${tool.label} has already been added.'`);
  //     this._toolsList.add(tool.key);
  //     window.threekit.player.tools.addTool(tool);
  //     window.threekit.player.tools.removeTool('pan');
  //   });
  // }

  setLanguage(language) {
    if (!language) return;
    this._currentLanguage = language;
    return this.getAttributes();
  }

  getLanguage() {
    return this._currentLanguage;
  }

  getLanguageOptions() {
    return Object.keys(Object.values(this._translations)[0]);
  }

  getAttributes(attrNames) {
    const attributes = window.threekit.configurator
      .getDisplayAttributes()
      .filter((el) => el.name !== 'CameraAngle');
    const attributesObj =
      attrNames?.reduce(
        (output, el) => Object.assign(output, { [el]: undefined }),
        {}
      ) || {};
    return attributes.reduce((output, attr) => {
      if (attrNames && !attrNames.includes(attr.name)) return output;
      return Object.assign(output, {
        [attr.name]: attr,
        //[attr.name]: this._translateAttribute(attr),
      });
    }, attributesObj);
  }

  async setAttributes(configuration) {
    const updatedAttrNames = await this._updateConfiguration(configuration);
    if (!updatedAttrNames.length) return {};
    return this.getAttributes();
  }

  getSku(config) {
    const configuration = window.threekit.configurator.getConfiguration();
    if (configuration[SKU_ATTRIBUTE_NAME])
      return [configuration[SKU_ATTRIBUTE_NAME]];
    const attributeSelections = this._getAttributeValues(config);
    return Object.entries(attributeSelections).reduce(
      (output, [attrName, attrVal]) => {
        let value;
        if (this._codeData[attrName]?.type.toLowerCase() === 'string')
          value = this._codeData[attrName].skuValues?.[attrVal.value] || '';
        else if (attrVal.metadata?.[METADATA_RESERVED.sku]?.length)
          value = attrVal.metadata[METADATA_RESERVED.sku];

        if (value?.length) return [...output, value];
        return output;
      },
      []
    );
  }

  getReadableConfiguration(config) {
    const attributesArr =
      window.threekit.configurator.getDisplayAttributes(config);
    return attributesArr.filter(assetsRules).reduce((output, attr) => {
      let value;
      if (attr.values) {
        value = attr.values.find((el) => {
          if (attr.type.toLowerCase() === 'asset')
            return el.assetId === attr.value.assetId;
          else return el.value === attr.value;
        });
      }
      if (attr.value && attr.type == 'String' && attr.values.length == 0) {
        value = attr.value;
      }

      return Object.assign(output, {
        [attr.name]: {
          value: value?.name || value?.label || (value && value),
          thumbnail:
            value?.metadata?.thumbnailPath ||
            value?.metadata?.thumbnail ||
            undefined,
        },
      });
    }, {});
  }

  getOutputs(config) {
    const preppedConfig = Object.assign(
      {
        sku: { includeHidden: false },
        industrialCode: { includeHidden: true },
        readableConfiguration: { includeHidden: true },
      },
      config
    );

    const sku = this.getSku(preppedConfig.sku);
    const readableConfiguration = this.getReadableConfiguration(
      preppedConfig.readableConfiguration
    );

    const threekitConfiguration =
      window.threekit.configurator.getConfiguration();

    //   function encodeToUnicodeEscape(input) {
    //     return input.replace(/[\s\S]/g, function (char) {
    //         if (char === ' ') {
    //             return '\\u0020';
    //         } else {
    //             const charCode = char.charCodeAt(0);
    //             return '\\u' + charCode.toString(16).padStart(4, '0');
    //         }
    //     });
    // }

    // if (readableConfiguration) {
    //     readableConfiguration.EngravingText.value = encodeToUnicodeEscape(
    //         readableConfiguration.EngravingText?.value
    //     );
    // }

    return {
      sku,
      readableConfiguration,
      threekitConfiguration,
    };
  }

  saveConfiguration(priceWithCurrency, productName) {
    return new Promise(async (resolve) => {
      const attachments = await this.takeSnapshots();
      let configuration = window.threekit.configurator.getConfiguration();
      let preppedConfiguration = configuration;
      let metadataPrepped = Object.assign({}, this.getOutputs(), {
        price: priceWithCurrency,
        productName: productName,
      });

      if (!preppedConfiguration) {
        preppedConfiguration = window.threekit.configurator.getConfiguration();
        preppedConfiguration = Object.entries(preppedConfiguration).reduce(
          (output, [attrName, attrData]) =>
            attrName.startsWith('_')
              ? output
              : Object.assign(output, { [attrName]: attrData }),
          {}
        );
      }

      const [response, error] = await threekitAPI.configurations.save({
        assetId: window.threekit.player.assetId,
        configuration: preppedConfiguration,
        metadata: metadataPrepped,
        attachments,
      });

      if (error) resolve(undefined);

      const params = Object.assign(getParams(), {
        [TK_SAVED_CONFIG_PARAM_KEY]: response.shortId,
      });
      const url = window.location.href.replace(window.location.search, '');

      const output = {
        ...response,
        resumableUrl: `${url}${objectToQueryStr(params)}`,
        thumbnailUrls: Object.values(response.attachments),
      };

      this._savedConfiguration = JSON.stringify(output);

      resolve(output);
    });
  }

  resumeConfiguration(configurationId) {
    return new Promise(async (resolve) => {
      try {
        const config = await Controller.getConfiguration(configurationId);
        this.setAttributes(config?.variant || config?.configuration || config);
        resolve(config);
      } catch (e) {
        throw new Error(e);
      }
    });
  }
}

export default Controller;
