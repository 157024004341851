import { createSlice } from '@reduxjs/toolkit';
import * as themeReducers from './reducers';

const initialState = {
  themeName: 'default',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    ...themeReducers,
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;

export * from './reducers';
export * from './selectors';
