import {
  setAttributes,
  setForm,
  setPlayerLoading,
  setPrice,
  setSku,
  setValidAttributes,
} from '..';
import { RESET_STEP_CAROUSEL } from '../../../utils/constants';
import { getDefaultAttributesValues } from '../../../utils/functions';
import { setStep } from '../../globalSettingsSlicer/index.js.js';

export const configurationReducers = {
  setProduct(state, action) {
    state.configuration.product = action.payload;
  },
  setForm(state, action) {
    state.configuration.form = action.payload;
  },
  setAttributes(state, action) {
    const newAttributes = Object.assign(
      {},
      state.configuration.attributes,
      action.payload
    );
    state.configuration.attributes = { ...newAttributes };
  },
  setValidAttributes(state, action) {
    const newValidAttributes = action.payload.map((attribute) => {
      return {
        ...attribute,
      };
    });
    state.configuration.dataDrivenConfiguratorExtensionStatus.validAttributes =
      [...newValidAttributes];
  },
  setSku(state, action) {
    state.configuration.dataDrivenConfiguratorExtensionStatus.sku =
      action.payload;
  },
  setFormValidAttributes(state, action) {
    state.configuration.formValidAttributes = action.payload;
  },
  setMetadata(state, action) {
    state.configuration.metadata = action.payload;
  },
  setPrice(state, action) {
    state.configuration.price = action.payload;
  },
  setName(state, action) {
    state.configuration.name = action.payload;
  },
  setNestedAttributesState(state, action) {
    state.configuration.nestedAttributes = action.payload;
  },
  setNestedAttributeAddressState(state, action) {
    state.configuration.nestedAttributeAddress = action.payload;
  },

  setActiveAttribute(state, action) {
    state.configuration.activeAttribute = action.payload;
  },
  setDependencies(state, action) {
    state.configuration.dependencies = action.payload;
  },
};

const setDataDrivenFewConfiguration = async (attributes) => {
  for (const [key, value] of Object.entries(attributes)) {
    await window.dataDrivenConfigurator.setConfiguration({
      [key]: value,
    });
  }
};

export const updateModelPresentationOnDataDriven =
  () => async (dispatch, getState) => {
    const { threekit } = getState();
    const currentModelPresentation =
      threekit?.configuration?.attributes?.['Model Presentation']?.value;
    await window.dataDrivenConfigurator.setConfiguration({
      'Model Presentation': currentModelPresentation,
    });
  };

export const setConfiguration = (config) => async (dispatch, getState) => {
  let preppedConfig = config || {};

  //dispatch(setPlayerLoading(true));
  console.log('preppedConfig!!!', preppedConfig);

  if (Object.keys(preppedConfig).length === 1) {
    await window.dataDrivenConfigurator.setConfiguration(preppedConfig);
  } else {
    await setDataDrivenFewConfiguration(preppedConfig);
  }
  const updatedAttributes = await window.threekit.controller.getAttributes();

  dispatch(setForm(window.threekit.controller.getForm(preppedConfig)));
  dispatch(setAttributes({ ...updatedAttributes }));
  dispatch(setPrice(window.threekit.controller.getPrice()));
  //dispatch(setPlayerLoading(false));
};

export const resetFormToDefaultValues =
  ({ newStep = RESET_STEP_CAROUSEL, attributesNamesToReset }) =>
  async (dispatch, getState) => {
    const attributesData = attributesNamesToReset
      ? Object.fromEntries(
          Object.entries(
            getState()?.threekit?.configuration?.attributes
          )?.filter(([key, value]) =>
            attributesNamesToReset?.includes(value?.name)
          )
        )
      : getState()?.threekit?.configuration?.attributes;
    const defaultAttributesValues = getDefaultAttributesValues(attributesData);
    dispatch(setConfiguration(defaultAttributesValues));
    if (!attributesNamesToReset) {
      // window.threekit.configurator.resetConfiguration();
      dispatch(setStep(newStep));
    }
    // dispatch(setAllDoNotWishEngravingToggles(false));
    // dispatch(setAllEnableSimplifiedChineseToggles(false));
    // dispatch(setDoNotWishEngravingToggle2(false));
  };

// export const setNestedConfiguration = (configuration) => async (
//   dispatch,
//   getState
// ) => {
//   if (!configuration || !Object.keys(configuration)?.length) return;
//   const { threekit } = getState();

//   if (!threekit?.configuration.nestedAttributeAddress?.length) return;

//   const updatedNestedAttributes = await window.threekit.controller.setNestedAttribute(
//       threekit.configuration.nestedAttributeAddress,
//       configuration
//   );
//   dispatch(configurationReducers.setNestedAttributesState(updatedNestedAttributes));
// };

// export const setNestedAttributeAddress = (address) => (dispatch, getState) => {
//   if (!address?.length) {
//       dispatch(configurationReducers.setNestedAttributeAddressState(undefined));
//       dispatch(configurationReducers.setNestedAttributesState(undefined));
//       return;
//   }

//   const addr = Array.isArray(address) ? address : [address];
//   const { threekit } = getState();

//   if (
//       JSON.stringify(addr) ===
//       JSON.stringify(threekit.configuration.nestedAttributeAddress)
//   )
//       return;

//   dispatch(configurationReducers.setNestedAttributeAddressState(addr));
//   const nestedAttributes = window.threekit.controller.getNestedAttribute(
//       address
//   );
//   dispatch(configurationReducers.setNestedAttributesState(nestedAttributes));
// };

// export const stepHistory = (step) => async (dispatch) => {
//   if (isNaN(step) || step === 0) return;
//   const updatedState = await window.threekit.controller.stepHistoryPosition(
//       step
//   );
//   if (updatedState) dispatch(configurationReducers.setAttributes(updatedState));
// };

// export const moveItemWithinArray = (arrayLabel) => (
//   fromIdx,
//   toIdx,
//   config
// ) => async (dispatch, getState) => {
//   if (isNaN(fromIdx) || isNaN(fromIdx)) return;
//   const { threekit } = getState();

//   const { method } = Object.assign({ method: 'move' }, config);

//   const attributesRegExp =
//       typeof arrayLabel === 'string'
//           ? new RegExp(`${arrayLabel} [0-9]`)
//           : arrayLabel;
//   const arrayAttributes = filterAttributesArray(
//       attributesRegExp,
//       threekit.configuration.attributes
//   );

//   let error;
//   const options = Object.values(arrayAttributes)[0].values.reduce(
//       (output, item) => Object.assign(output, { [item.assetId]: item }),
//       {}
//   );

//   let updatedArray;
//   switch (method) {
//       case 'move':
//           updatedArray = Object.values(arrayAttributes).reduce(
//               (output, attr, idx, srcArray) => {
//                   if (idx === fromIdx) return output;
//                   output.push(attr);
//                   if (idx === toIdx) output.push(srcArray[fromIdx]);
//                   return output;
//               },
//               []
//           );
//           break;
//       default:
//           break;
//   }

//   //  Default Validator
//   error = arrayValidator(options, updatedArray);
//   if (error) return message.warning(error);

//   //  Custom Validator
//   if (MIDDLEWARE.arrayValidation?.[arrayLabel])
//       error = MIDDLEWARE.arrayValidation?.[arrayLabel](options, updatedArray);
//   if (error) return message.warning(error);

//   const arrayConfigurationObj = filterAttributesArray(
//       attributesRegExp,
//       window.threekit.configurator.getConfiguration()
//   );
//   const attributeKeys = Object.keys(arrayConfigurationObj);
//   const arrayConfiguration = Object.values(arrayConfigurationObj);

//   let updatedConfiguration;
//   switch (method) {
//       case 'move':
//           updatedConfiguration = arrayConfiguration.reduce(
//               (output, configuration, idx, srcArray) => {
//                   if (idx === fromIdx) return output;
//                   output = Object.assign(output, {
//                       [attributeKeys.shift()]: configuration,
//                   });
//                   if (idx === toIdx)
//                       output = Object.assign(output, {
//                           [attributeKeys.shift()]: srcArray[fromIdx],
//                       });
//                   return output;
//               },
//               {}
//           );
//           break;
//       default:
//           break;
//   }
//   dispatch(setConfiguration(updatedConfiguration));
// };

// export const deleteItemFromArray = (arrayLabel) => (idx) => async (
//   dispatch,
//   getState
// ) => {
//   if (isNaN(idx)) return;
//   const { threekit } = getState();

//   const attributesRegExp = new RegExp(`${arrayLabel} [0-9]`);
//   const arrayAttributes = filterAttributesArray(
//       attributesRegExp,
//       threekit.configuration.attributes
//   );

//   let error;
//   const options = Object.values(arrayAttributes)[0].values.reduce(
//       (output, item) => Object.assign(output, { [item.assetId]: item }),
//       {}
//   );
//   const updatedArray = Object.values(arrayAttributes).filter(
//       (_, i) => i !== idx
//   );

//   //  Default Validator
//   error = arrayValidator(options, updatedArray);
//   if (error) return message.warning(error);

//   //  Custom Validator
//   if (MIDDLEWARE.arrayValidation?.[arrayLabel])
//       error = MIDDLEWARE.arrayValidation?.[arrayLabel](options, updatedArray);
//   if (error) return message.warning(error);

//   const arrayConfigurationObj = filterAttributesArray(
//       attributesRegExp,
//       window.threekit.configurator.getConfiguration()
//   );
//   const arrayConfiguration = Object.entries(arrayConfigurationObj);
//   const updatedConfiguration = arrayConfiguration.reduce(
//       (output, [attributeName], i) => {
//           if (i < idx) return output;
//           if (!arrayConfiguration[i + 1])
//               return Object.assign(output, {
//                   [attributeName]: { assetId: '' },
//               });

//           return Object.assign(output, {
//               [attributeName]: arrayConfiguration[i + 1][1],
//           });
//       },
//       {}
//   );

//   dispatch(setConfiguration(updatedConfiguration));
// };

// export const addItemToArray = (arrayLabel) => (
//   assetId,
//   addToIdx = undefined
// ) => async (dispatch, getState) => {
//   if (!assetId?.length) return;
//   const { threekit } = getState();

//   const attributesRegExp = new RegExp(`${arrayLabel} [0-9]`);
//   const arrayAttributes = filterAttributesArray(
//       attributesRegExp,
//       threekit.configuration.attributes
//   );

//   let updateAttrIdx = addToIdx;
//   let attributeToUpdate;

//   if (isNaN(updateAttrIdx))
//       attributeToUpdate = Object.values(arrayAttributes).find((el, idx) => {
//           if (!el.value.assetId?.length) {
//               updateAttrIdx = idx;
//               return true;
//           }
//       });
//   else attributeToUpdate = arrayAttributes[updateAttrIdx];

//   if (!attributeToUpdate) return message.warning('Max items reached');

//   let error;
//   const options = Object.values(arrayAttributes)[0].values.reduce(
//       (output, item) => Object.assign(output, { [item.assetId]: item }),
//       {}
//   );
//   const updatedArray = Object.values(arrayAttributes).reduce(
//       (output, attr, i) => {
//           if (i !== updateAttrIdx) output.push(attr);
//           else output.push({ ...attr, value: { assetId } });
//           return output;
//       },
//       []
//   );

//   //  Default Validator
//   error = arrayValidator(options, updatedArray);
//   if (error) return message.warning(error);

//   //  Custom Validator
//   if (MIDDLEWARE.arrayValidation?.[arrayLabel])
//       error = MIDDLEWARE.arrayValidation?.[arrayLabel](options, updatedArray);
//   if (error) return message.warning(error);

//   dispatch(setConfiguration({ [attributeToUpdate.name]: { assetId } }));
// };
