import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const location = window.location.host.includes('localhost');
const baseURL = location ? 'http://localhost:5000' : '';

export const fetchStockQuantity = createAsyncThunk(
  'product/fetchStockQuantity',
  async ({ storeCode, sku }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/getStockQuantity`, {
        params: { storeCode, sku },
      });
      return response.data.totalQuantity;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLeadTime = createAsyncThunk(
  'product/fetchLeadTime',
  async ({ storeCode, sku, country }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/getSkuAvailability`, {
        params: { storeCode, sku, country },
      });
      return response.data.distribution_leadtime;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPrice = createAsyncThunk(
  'product/fetchPrice',
  async ({ storeCode, sku, country }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}/getPrice`, {
        params: { storeCode, sku, country },
      });
      return response.data.priceData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const extraReducers = (builder) => {
  builder
    .addCase(fetchStockQuantity.pending, (state) => {
      state.stock.isLoading = true;
    })
    .addCase(fetchStockQuantity.fulfilled, (state, action) => {
      state.stock.isLoading = false;
      state.stock.quantity = action.payload;
    })
    .addCase(fetchStockQuantity.rejected, (state, action) => {
      state.stock.isLoading = false;
      state.stock.quantity = 0;
      state.stock.error = action.payload;
    })
    .addCase(fetchLeadTime.pending, (state) => {
      state.leadTime.isLoading = true;
    })
    .addCase(fetchLeadTime.fulfilled, (state, action) => {
      state.leadTime.isLoading = false;
      state.leadTime.leadTime = action.payload;
    })
    .addCase(fetchLeadTime.rejected, (state, action) => {
      state.leadTime.isLoading = false;
      state.leadTime.leadTime = { min: null, max: null };
      state.leadTime.error = action.payload;
    })
    .addCase(fetchPrice.pending, (state) => {
      state.price.isLoading = true;
    })
    .addCase(fetchPrice.fulfilled, (state, action) => {
      state.price.isLoading = false;
      state.price.priceData = action.payload;
    })
    .addCase(fetchPrice.rejected, (state, action) => {
      state.price.isLoading = false;
      state.price.priceData = null;
      state.price.error = action.payload;
    });
};

export const reducers = {
  setProductStockQuantity: (state, action) => ({
    ...state,
    stock: {
      ...state.stock,
      quantity: action.payload,
    },
  }),
  setProductStockIsLoading: (state, action) => ({
    ...state,
    stock: {
      ...state.stock,
      isLoading: action.payload,
    },
  }),
  setProductStockError: (state, action) => ({
    ...state,
    stock: {
      ...state.stock,
      error: action.payload,
    },
  }),
  setProductLeadTimeleadTime: (state, action) => ({
    ...state,
    leadTime: {
      ...state.leadTime,
      leadTime: action.payload,
    },
  }),
  setProductLeadTimeIsLoading: (state, action) => ({
    ...state,
    leadTime: {
      ...state.leadTime,
      isLoading: action.payload,
    },
  }),
  setProductLeadTimeError: (state, action) => ({
    ...state,
    leadTime: {
      ...state.leadTime,
      error: action.payload,
    },
  }),
  setProductPricePrice: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      priceData: action.payload,
    },
  }),
  setProductPriceIsLoading: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      isLoading: action.payload,
    },
  }),
  setProductPriceError: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      error: action.payload,
    },
  }),
};
