import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { ButtonContainer } from './OptionsButton.style';
import {
  SHARE_ICON,
  V_ICON,
} from '../../../assets';
import {
  SHARE_BUTTON_LABEL
} from '../../../utils/constants';
import {
  copyTextToClipboard,
} from '../../../utils/functions';
import PopMessage from '../../PopMessage';

const OptionsButton = () => {

  const [linkCopied, setLinkCopied] = useState(false);

  const handleShare = useCallback(async () => {
    const clientUrl = window.location;
    if (!clientUrl) {
      console.error('No clientUrl found (probably no configId)');
      return;
    }
    await copyTextToClipboard(clientUrl);
    setLinkCopied(true);
  }, []);

  let iconSrc = SHARE_ICON;
  let functionOnclick = handleShare;

  return (
    <>
      <PopMessage
        show={linkCopied}
        image={V_ICON}
        text={'Link copied to clipboard'}
        ttl={3000}
        onClose={() => setLinkCopied(false)}
      />
      <ButtonContainer
        onClick={(e) => {
          functionOnclick(e);
        }}
      >
        {iconSrc && <img alt="icon" src={iconSrc} />}
      </ButtonContainer>
    </>
  );
}

export default OptionsButton;
