import { styled } from 'styled-components';
import { OOB_APPNAME, TK_PLAYER_DIV_ID_2D, TK_PLAYER_DIV_ID_3D } from '../../../utils/constants';

export const Wrapper = styled.div
  .withConfig({
    shouldForwardProp: (prop) =>
      !['height', 'minHeight', 'maxHeight', 'width', 'border', 'isRotable'].includes(prop),
  })
  .attrs((props) => ({
    style: {
      height: props.height,
      minHeight: props.minHeight,
      maxHeight: props.maxHeight,
      width: props.width,
      cursor: props.isRotable ? 'grab' : 'default'
    },
  }))(
    ({ theme: { appName }, border }, isRotable) => `
    position: relative;
    user-select: none;
    height: 100svh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    ${isRotable ? `cursor: grab;` : `cursor: default;`}
    
    &:active {
      cursor: grabbing;
    }


    ${border ? `${border};` : ''}

    @media (min-width: 1025px) {
      width: ${appName === OOB_APPNAME ? '50vw' : '60vw'};
    }

    #${TK_PLAYER_DIV_ID_2D} {
      height: 100%;
      min-height: 50vw;
      width: 100%;

    @media (max-width: 768px) {
      min-height: 40%;
      width: 100%;
      top: 0;
      transform: translate(0%, 0%);
    }

      div[class^='threekit'],
      div[class*=' threekit'] {
        div[class^='holder'],
        div[class*=' holder'] {
          div[class^='player'],
          div[class*=' player'] {
            div[class^='logo'],
            div[class*=' logo'] {
              display: none;
            }
          }
        }
      }
    }

    #${TK_PLAYER_DIV_ID_3D} {
      height: 100%;
      min-height: 50vw;
      width: 100%;

    @media (max-width: 768px) {
      min-height: 40%;
      width: 100%;
      top: 0;
      transform: translate(0%, 0%);
    }

      div[class^='threekit'],
      div[class*=' threekit'] {
        div[class^='holder'],
        div[class*=' holder'] {
          div[class^='player'],
          div[class*=' player'] {
            div[class^='logo'],
            div[class*=' logo'] {
              display: none;
            }
          }
        }
      }
    }
  `
  );
