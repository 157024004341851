// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { mappingLanguage, getParams } from '../utils/functions';
import threekitApi from '../services';

export const initializeI18n = async (translationTableId) => {
  const params = getParams();
  const lng = mappingLanguage(params['lng'] || 'en_E1');

  const options = {
    debug: true,
    lng: lng,
    fallbackLng: 'en_E1',
    interpolation: {
      escapeValue: false,
    },
  };

  try {
    const translation =
      await threekitApi.configurations.fetchThreekitDatatableTranslations(
        'json',
        translationTableId,
        lng,
      );

    if (translation && Object.keys(translation).length > 0) {
      options.resources = {
        [lng]: {
          translation: translation,
        },
      };
    }
    else {
      console.error('Error: Translation is empty or not available.');
    }
  } catch (error) {
    console.error('Error fetching translation:', error);
  }

  i18n.use(HttpApi).use(initReactI18next).init(options);
};
