import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import GlobalStyle from './styles/global';

const Project = () => {
  return (
    <GlobalStyle>
      <Provider store={store}>
        <App />
      </Provider>
    </GlobalStyle>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Project />);
