import { useEffect } from 'react';
import { CATALOGWECOM_APPNAME, PAGES_TITLES } from '../../utils/constants';
import { getParams } from '../../utils/functions';

function usePageTitle() {
  const params = getParams();
  const isChina = params?.appName === CATALOGWECOM_APPNAME;
  useEffect(() => {
    const title = isChina ? PAGES_TITLES['client']?.zh : PAGES_TITLES['client']?.en;
    if (title) document.title = title;
  }, []);
}

export default usePageTitle;
