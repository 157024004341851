import { configureStore } from '@reduxjs/toolkit';
import globalSettingsReducer from './globalSettingsSlicer/index.js.js';
import themeReducer from './themeSlicer/index.js.js';
import threekitReducer from './threekitSlicer/index.js';
import productReducer from './productSlicer/index.js';

const store = configureStore({
  reducer: {
    globalSettings: globalSettingsReducer,
    theme: themeReducer,
    threekit: threekitReducer,
    product: productReducer,
  },
});

export default store;
