export const setGlobalSettings = (state, action) => {
  return action.payload;
};

export const setGlobalSettingsParams = (state, action) => {
  return {
    ...state,
    params: action.payload,
  };
};

export const setStep = (state, action) => {
  return {
    ...state,
    step: action.payload,
  };
};

export const setFormRequiredAndChecked = (state, action) => {
  return {
    ...state,
    form: {
      requiredAndChecked: action.payload,
    },
  };
};
