import React from 'react';
import { t } from 'i18next';
import { useWindowSize } from '../../utils/threekitHooks';
import OptionsButton from '../Form/OptionsButton';
import {
  Container,
  ProductSKU,
  ProductName,
  ProductPrice,
  InnerContainer,
  ShareAndLeadtime,
} from './TopSection.styles';
import {
  PRICE_PLACEHOLDER,
  SHARE_BUTTON_LABEL,
} from '../../utils/constants';
import { useProductName } from '../../hooks';
import { getParams } from '../../utils/functions';

const TopSection = ({ clientPage }) => {
  const { isMobile } = useWindowSize();
  const { sku, price, productName } = getParams();
  const productNameThreekit = useProductName();
  const productNameToDisplay = clientPage ? productName : productNameThreekit;
  const priceToDisplay = price || PRICE_PLACEHOLDER;

  return (
    <Container clientPage={clientPage}>
      {isMobile ? (
        <>
          <ProductSKU>{sku}</ProductSKU>
          <OptionsButton />
          <ProductName>
            {t(productNameToDisplay, productNameToDisplay)}
          </ProductName>
          <ProductPrice>{priceToDisplay || ''}</ProductPrice>
        </>
      ) : (
        <>
          <InnerContainer>
            <ProductSKU>{sku}</ProductSKU>
            <ProductName>{t(productNameToDisplay, productNameToDisplay)}</ProductName>
            <ProductPrice>{priceToDisplay || ''}</ProductPrice>
          </InnerContainer>
          <ShareAndLeadtime>
            <OptionsButton />
          </ShareAndLeadtime>
        </>
      )}
    </Container>
  );
};

export default TopSection;
