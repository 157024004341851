import { createSlice } from '@reduxjs/toolkit';
import { reducers as productReducers, extraReducers } from './reducers';

const initialState = {
  stock: {
    quantity: 0,
    isLoading: true,
    error: null,
  },
  leadTime: {
    leadTime: { min: null, max: null },
    isLoading: true,
    error: null,
  },
  price: {
    priceData: null,
    isLoading: true,
    error: null,
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    ...productReducers,
  },
  extraReducers,
});

export const {
  setProductStockQuantity,
  setProductStockIsLoading,
  setProductStockError,
  setProductLeadTimeleadTime,
  setProductLeadTimeIsLoading,
  setProductLeadTimeError,
  setProductPriceleadTime,
  setProductPriceIsLoading,
  setProductPriceError,
} = productSlice.actions;
export default productSlice.reducer;

export * from './reducers';
export * from './selectors';
