export const getSettings = (state) => state?.threekit?.settings;

export const getConfig = (state) => state?.threekit?.settings?.config;

export const isLoading = (state) => state?.threekit?.settings?.isLoading;

export const isClient = (state) => state?.threekit?.settings?.isClient;

export const getIsChina = (state) => state?.threekit?.settings?.isChina;

export const getUserEmail = (state) => state?.threekit?.settings?.userEmail;

export const isThreekitLoaded = (state) =>
  state?.threekit?.settings?.isThreekitLoaded;

export const isPlayerLoading = (state) =>
  state?.threekit?.settings?.isPlayerLoading;

export const getLanguage = (state) => state?.threekit?.settings?.language;

export const getLanguageOptions = (state) => {
  if (!state.threekit.settings.isThreekitLoaded) return [];
  return window.threekit.controller.getLanguageOptions();
};

export const getAllowInPlayerReorder = (state) =>
  state?.threekit?.settings?.allowInPlayerReorder;

export const getAllowInPlayerSelect = (state) =>
  state?.threekit?.settings?.allowInPlayerSelect;
