import React, { useEffect, useMemo, useState } from 'react';
import threekitAPI from '../../services'
import {
  Container,
  ConfigurationSection,
  ContentWrapper,
} from './ClientPage.styles';
import { getParams, isEmpty } from '../../utils/functions';
import { LVLoader, PhotosDisplayer } from '../../components';
import { useThreekitInitStatus } from '../../utils/threekitHooks';
import { MAIN_ATTACHMENT_KEY } from '../../utils/constants';
import TopSection from '../../components/TopSection/TopSection';
import { usePageTitle } from '../../hooks';
import ConfigurationSectionContent from '../components/ConfigurationSectionContent';

const ClientPage = () => {
  usePageTitle({ page: 'client' });
  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [dataUpToDate, setDataUpToDate] = useState(false);
  const [configuration, setConfigurationSave] = useState(null);
  const [attributesToDisplay, setAttributesToDisplay] = useState();
  const isLoaded = useThreekitInitStatus();
  // const validAttributesFromDataDriven =
  //   window.dataDrivenConfiguratorExtension?.getStatus()
  //     ?.validAttributesAndTheirValues || [];

  const { attachments } = useMemo(() => {
    if (!configuration) return { attachments: [], productName: '', price: '' };
    const attachments = configuration?.attachments || [];
    return { attachments };
  }, [configuration]);

  useEffect(() => {
    if (isEmpty(attachments)) setPhotoLoaded(true);
  }, [attachments]);

  const loading = !photoLoaded || !isLoaded || !dataUpToDate;

  useEffect(() => {
    const { configId } = getParams();

    const fetchData = async () => {
      try {
        const response = await threekitAPI.configurations.fetchThreekit(configId);
        const result = await response.json();
        if (result) setConfigurationSave(result);
        else { return; }

        const { metadata } = result || {};
        const { threekitConfiguration, readableConfiguration } = metadata || {};

        if (!threekitConfiguration || !readableConfiguration) {
          return;
        }

        setAttributesToDisplay(Object.entries(readableConfiguration));
        setDataUpToDate(true);
      } catch (error) {
        console.error('Error fetching Threekit configuration:', error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const { configId } = getParams();
  //   const fetchConfiguration = async () => {
  //     const data = await window.threekit?.controller?.resumeConfiguration(
  //       configId
  //     );
  //     const result = await data.json();
  //     if (result?.configuration) {
  //       await waitForDataDrivenConfigurator();
  //       await waitForDataDrivenExtensionConfigurator();
  //       const configurationEntries = Object.entries(result.configuration);

  //       dispatch(setConfiguration({ dataDrivenConfiguratorToolSwitch: 'On' }));
  //       configurationEntries.forEach(([key, value], index) => {
  //         if (key !== 'dataDrivenConfiguratorToolSwitch') {
  //           dispatch(setConfiguration({ [key]: value }));
  //         }
  //       });
  //     }
  //     setConfigurationSave(result);
  //     setDataUpToDate(true)
  //   };

  //   if (isLoaded && configId) fetchConfiguration();
  // }, [isLoaded, dispatch]);

  return attributesToDisplay && (
    <>
      <LVLoader display={loading} />
      <Container isLoading={loading} >
        <PhotosDisplayer
          photos={attachments}
          mainKey={MAIN_ATTACHMENT_KEY}
          setPhotoLoaded={setPhotoLoaded}
          showPlayer={true}
          playerKey={MAIN_ATTACHMENT_KEY}
          clientPage
        />
        <ContentWrapper>
          <TopSection clientPage />
          <ConfigurationSection>
            <ConfigurationSectionContent
              attributesToDisplay={attributesToDisplay}
            />
          </ConfigurationSection>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default ClientPage;

