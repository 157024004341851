import React, { useEffect } from 'react';
import Router from './router/Router';
import { ThemeProvider } from 'styled-components';
import useWindowSize from './utils/threekitHooks/useWindowSize/index.js';
import { getParams, mappingLanguage } from './utils/functions.js';
import { useDispatch } from 'react-redux';
import { CATALOGWECOM_APPNAME } from './utils/constants.js';
import { launch } from './store/threekitSlicer/index.js';
import { setGlobalSettings } from './store/globalSettingsSlicer/index.js.js';
import { initializeI18n } from './i18n/i18n.js';

const App = () => {
  const dispatch = useDispatch();
  const { isIpad, isDesktop, isMobile } = useWindowSize();
  const { appName } = getParams();
  const isMesurable = isIpad || isDesktop || isMobile;

  useEffect(() => {
    if (!isMesurable) return;
    const fetchThreekitConfigs = async () => {
      try {
        const response = await fetch('/getConfigVariables');
        const config = await response.json();
        initializeI18n(config.translationTableId);
        const params = getParams();
        const isChina = params?.appName === CATALOGWECOM_APPNAME;
        config.isChina = isChina;
        config.locale = mappingLanguage(params?.lng) || 'FR';
        config.compression = {
          "imageResolution": isMobile ? "512" : "1024",
        }
        //dispatch(launch(config));

      } catch (error) {
        console.error(error);
      }
    };

    fetchThreekitConfigs();
  }, [dispatch, isMesurable]);

  const theme = {
    device: { isIpad, isDesktop, isMobile },
    appName: appName?.toLowerCase(),
  };

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;
