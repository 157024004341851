import { createSlice } from '@reduxjs/toolkit';
import * as globalSettingsReducers from './reducers';

const initialState = {
  step: -1,
  params: {},
  form: {
    requiredAndChecked: {},
  },
};

const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {
    ...globalSettingsReducers,
  },
});

export const {
  setGlobalSettings,
  setGlobalSettingsParams,
  setStep,
  setFormRequiredAndChecked,
} = globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;

export * from './reducers';
export * from './selectors';
