import {
  setAttributes,
  setConfig,
  setConfiguration,
  setForm,
  setLanguageState,
  setMetadata,
  setName,
  setPlayerLoading,
  setPrice,
  setProduct,
  setThreekitLoaded,
} from '..';
import Controller from '../../../controller';
import { DEFAULT_PLAYER_CONFIG } from '../../../utils/constants';

export const playerReducers = {
  setPhase(state, action) {
    state.player.phase = action.payload;
  },
  setPlayerConfiguration(state, action) {
    state.player.configuration = action.payload;
  },
  setTools(state, action) {
    state.player.tools = action.payload;
  },
  setPlayerSettings(state, action) {
    state.player.params = action.payload;
  },
};

export const launch = (config) => async (dispatch) => {
  const launchConfig = Object.assign(
    {},
    DEFAULT_PLAYER_CONFIG,
    Object.keys(DEFAULT_PLAYER_CONFIG).reduce((output, key) => {
      if (config[key] === undefined) return output;
      return Object.assign(output, { [key]: config[key] });
    }, {}),
    {
      orgId: config.orgId,
      threekitEnv: config.threekitEnv,
      serverUrl: config.serverUrl,
      language: config.language,
      additionalTools: config.additionalTools,
      translationTableId: config.translationTableId,
      isChina: config.isChina,
      compression: config.compression,
    }
  );

  await Controller.launch(launchConfig);

  // const imageSlider = new Promise(async (resolve) => {
  //     const main = await window?.threekit?.controller?.snapshot();
  //     const attachments = {main};
  //     let configuration = await window?.threekit?.configurator?.getConfiguration();
  //     const [response, error] = await threekitAPI?.configurations?.save({
  //         assetId: window?.threekit?.player?.assetId,
  //         configuration,
  //         attachments,
  //     });

  //     resolve(response?.attachments);
  // });

  // const productImage = await imageSlider;

  //dispatch(setThreekitLoaded(true));
  //dispatch(setPlayerLoading(false));
  //dispatch(actions.setImageURL(productImage?.main));
  dispatch(setConfig(window.threekit.controller.getConfig()));
  dispatch(setProduct(window.threekit.controller.getProduct()));
  dispatch(setForm(window.threekit.controller.getForm(config)));
  // dispatch(setName(window.threekit.controller.getName()));
  dispatch(setMetadata(window.threekit.configurator.getMetadata()));
  dispatch(setPrice(window.threekit.controller.getPrice()));
  // For updating the DataDriven Status
  dispatch(setConfiguration(window.threekit.configurator.getConfiguration()));

  if (config.language) {
    return dispatch(setLanguageState(config.language));
  }

  dispatch(setAttributes(window.threekit.controller.getAttributes()));
};
