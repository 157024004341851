import React, { useEffect, useMemo, useState } from 'react';
import {
  MainPhoto,
  MainPhotoWrapper,
  ElsePhoto,
  ElsePhotosWrapper,
  Wrapper,
  ElsePhotoWrapper,
  PlayerWrapper,
  Icon360,
} from './photosDisplayer.styled';
import { MAIN_ATTACHMENT_KEY } from '../../utils/constants';
import { AnimateItem, Player2D, Player3D, ViewsViewer } from '..';
import { ICON_360 } from '../../assets';
import { isEmpty } from '../../utils/functions';
import { useWindowSize } from '../../utils/threekitHooks';

function PhotosDisplayer({
  photos,
  mainKey = MAIN_ATTACHMENT_KEY,
  setPhotoLoaded,
  showPlayer = false,
  playerKey = MAIN_ATTACHMENT_KEY,
  clientPage
}) {
  const [mainPhotoKey, setMainPhotoKey] = useState(mainKey);
  const [playerSelected, setPlayerSelected] = useState(mainKey === playerKey);

  useEffect(() => {
    setPlayerSelected(mainPhotoKey === playerKey);
  }, [mainPhotoKey, playerKey]);

  const sortedPhotos = useMemo(() => {
    if (isEmpty(photos)) return photos;

    const sorted = {};
    sorted[mainKey] = photos[mainKey];
    Object.entries(photos).forEach(([key, value]) => {
      if (key !== mainKey) {
        sorted[key] = value;
      }
    });
    return sorted;
  }, [photos, mainKey]);

  const photoWidth = useMemo(() => {
    return photos ? 100 / Object.keys(photos).length + '%' : '20%';
  }, [photos]);


  return (
    <Wrapper>
      <MainPhotoWrapper>
        {/* {showPlayer && (
          <PlayerWrapper playerSelected={playerSelected}>
            <Player2D key="player" height="100%" clientPage={clientPage} >
              <AnimateItem />
            </Player2D>
          </PlayerWrapper>
        )} */}
        {Object.entries(sortedPhotos).map(([key, value]) => {
          const hidePhoto = mainPhotoKey !== key;
          // (showPlayer && (key === playerKey || playerSelected)) ||
          // mainPhotoKey !== key;
          return (
            <MainPhoto
              key={key}
              image={value}
              onLoad={() => setPhotoLoaded(true)}
              onAbort={() => setPhotoLoaded(true)}
              onError={() => setPhotoLoaded(true)}
              hidePhoto={hidePhoto}
            />
          );
        })}
      </MainPhotoWrapper>
      <ElsePhotosWrapper>
        {Object.entries(sortedPhotos).map(([key, value]) => (
          <ElsePhotoWrapper
            key={key}
            onClick={() => setMainPhotoKey(key)}
            selected={key === mainPhotoKey}
            width={photoWidth}
            image={value}
          >
            {/* {showPlayer && key === playerKey && <Icon360 src={ICON_360} />} */}
          </ElsePhotoWrapper>
        ))}
      </ElsePhotosWrapper>
    </Wrapper>
  );
}

export default PhotosDisplayer;
