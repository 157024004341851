import styled from 'styled-components';
import { OOB_APPNAME } from '../../../utils/constants';

export const AttributeValue = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page,
  }) => {
    return page === 'summary'
      ? `
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
          ${isMobile
        ? ' '
        : isIpad
          ? 'flex-direction: row-reverse;'
          : isDesktop
            ? 'flex-direction: row-reverse;'
            : ''
      }
          ${appName === OOB_APPNAME && isDesktop
        ? `
              text-transform: capitalize;
            `
        : ''
      }
            `
      : `
            display: flex;
            flex-direction: row-reverse;
          align-items: center;
          gap: 15px;
          font-size: 13px;
          text-transform: capitalize;
          ${appName === OOB_APPNAME && isDesktop
        ? `
              flex-direction: column;
              width: 100px;
              text-align: center;`
        : ''
      }
            `;
  }
);

export const TextSummary = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page,
  }) => {
    return page === 'summary'
      ? `
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
      `
      : '';
  }
);

export const AttributeThumbnail = styled.img(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    image,
    page,
  }) => {
    return page === 'summary'
      ? `
        width: 26px;
        height: 26px;
        border-radius: 5px;
        ${isMobile
        ? ''
        : isIpad
          ? 'width: 35px; height: 35px;'
          : isDesktop
            ? 'width: 35px; height: 35px;'
            : ''
      }
        ${appName === OOB_APPNAME && isDesktop
        ? `
            width: 26px;
            height: 26px;
            border-radius: 50%;
        `
        : ''
      }
      `
      : `
        background-image: url(${image});
        width: 30px;
        height: 30px;
        border-radius: 5px;
        ${isMobile
        ? ''
        : isIpad
          ? 'width: 35px; height: 35px;'
          : isDesktop
            ? 'width: 35px; height: 35px;'
            : ''
      }
        ${appName === OOB_APPNAME && isDesktop
        ? `
                width: 25px;
                height: 25px;
                border-radius: 50%;`
        : ''
      }
`;
  }
);

export const AttributeWrapper = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page,
  }) => {
    return page === 'summary'
      ? `
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
     
          ${isMobile
        ? 'flex-basis: 48%; padding-bottom: 30px; gap: 15px;'
        : isIpad
          ? 'flex-direction: row; justify-content: space-between; padding: 15px; border-bottom: 1px solid #E0D7D5; align-items: center;'
          : isDesktop
            ? 'flex-direction: row; justify-content: space-between; padding: 15px; border-bottom: 1px solid #E0D7D5;align-items: center;'
            : ''
      }
  
          ${appName === OOB_APPNAME && isDesktop
        ? `
              border-bottom: none;
              padding: 25px 20px;
            `
        : ''
      }
            `
      : `
          display: flex;
          gap: 15px;
          justify-content: space-between;
          align-items: center;

          ${isMobile
        ? ''
        : isIpad
          ? 'border-bottom: 1px solid #E0D7D5; padding: 20px;'
          : isDesktop
            ? 'border-bottom: 1px solid #E0D7D5; padding: 20px;'
            : ''
      }
          ${appName === OOB_APPNAME && isDesktop
        ? `
              padding: 25px 20px;
              border: none;
            `
        : ''
      }
            `;
  }
);

export const AttributeLabel = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page,
  }) =>
    page === 'summary'
      ? `
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          text-wrap: balance;
          ${appName === OOB_APPNAME && isDesktop
        ? `
              text-transform: capitalize;
            `
        : ``
      }
      `
      : `
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
      ${isMobile
        ? ''
        : isIpad
          ? 'font-size: 17px; font-weight: 600;'
          : isDesktop
            ? 'font-size: 17px; font-weight: 600;'
            : ''
      }
      `
);

export const BackgroundColor = styled.div.withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    backgroundColor,
  }) => `
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    ${isMobile
      ? ''
      : isIpad
        ? 'width: 35px; height: 35px;'
        : isDesktop
          ? 'width: 35px; height: 35px;'
          : ''
    }
    ${appName === OOB_APPNAME && isDesktop
      ? `
        width: 26px;
        height: 26px;
        border-radius: 50%;
    `
      : ''
    }
  `
);
